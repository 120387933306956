import React, { Component } from 'react'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { ProgressRing } from 'icons/ProgressRing/ProgressRing'

import { COLORS } from 'COLORS'
import { FlagIcon } from '@junglescout/edna'
import { CardSection } from 'ui_elements/CardSection/CardSection'
import { Modal } from '../../../common/junglekit/ui_elements/Modal/Modal'

const MODAL_WIDTH = '430px'

const CustomModal = styled(Modal)`
  width: ${MODAL_WIDTH};
  min-width: ${MODAL_WIDTH};
  max-width: ${MODAL_WIDTH};

  max-height: ${props => (props.hasMultipleAccounts ? '745px' : '575px')};
  overflow-y: hidden;

  .modal-header {
    max-width: unset;
    min-width: unset;
    width: unset;
  }

  .modal-content {
    overflow-y: hidden;
    height: 695px;
  }

  .modal-content:hover {
    overflow-y: ${props => (props.hasMultipleAccounts ? 'scroll' : 'unset')};
  }

  ${props => {
    if (!props.showFooterDrop) {
      return
    }

    return `
    .modal-content::after {
      content: ' ';
      height: 20px;
      width: 414px;
      background-color: ${COLORS.white};
      position: absolute;
      bottom: -6px;
      display: block;
      margin: 0;
      padding: 0;
      left: 0px;
      z-index: 2;
    }

    &.modal-parent::after {
      content: ' ';
      height: 20px;
      width: 368px;
      background-color: #ffffff;
      position: relative;
      top: -18px;
      display: block;
      box-shadow: inset 0px 2px 4px 0px #d8d8d8;
      margin-left: 45px;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      border-radius: 2%;
    }`
  }}
`

const IntroText = styled.p`
  ${TEXT_STYLES.BodyBlack}
  margin: 0;
`

const AccountWrapper = styled.div`
  display: block;
  margin-top: 15px;
  margin-bottom: 0;
  padding-bottom: 10px;

  img {
    margin-right: 5px;
  }

  h3 {
    ${TEXT_STYLES.H3Black}
    display: inline-block;
    margin-top: 0;
    margin-bottom: 5px;
  }

  h4 {
    ${TEXT_STYLES.H4Black}
    display: inline;
    position: relative;
    top: -6px;
  }
`

const Columns = styled.div`
  display: flex;
  flex-direction: row; /* confusing I know, but it works */
  margin-top: -10px;
`

const Column = styled.div`
  flex-basis: ${props => props.basis};
  max-width: ${props => props.basis};
`

const StageCard = styled(CardSection)`
  background-color: ${COLORS.grey050};
  display: block;
  float: right;
  padding-bottom: 10px;

  h4 {
    top: 0;
  }

  h5 {
    ${TEXT_STYLES.BodyBlack}
    display: inline;
    opacity: ${props => (props.isComplete ? '0.5' : 'unset')};
  }

  p {
    ${TEXT_STYLES.BodyBlack}
    margin: 0;
    margin-top: 1px;
  }

  svg {
    float: right;
  }
`

const CustomRing = styled(ProgressRing)`
  float: right;
`

const SYNC_STAGES = [
  {
    name: 'mws_sync',
    title: 'Amazon MWS Sync',
    description:
      'We need to successfully connect to Amazon to verify your account.'
  },
  {
    name: 'mws_finance',
    title: 'Finance',
    description:
      'We pull all your product information from Amazon directly into Jungle Scout. '
  },
  {
    name: 'mws_order',
    title: 'Order Data',
    description:
      'We need to successfully connect to Amazon to verify your account'
  },
  {
    name: 'mws_product',
    title: 'Product',
    description:
      'We need to successfully connect to Amazon to verify your account'
  },
  {
    name: 'ppc',
    title: 'Amazon PPC',
    description:
      'We need to successfully connect to Amazon to verify your account'
  }
]

export class MWSAccountSyncDetailsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showFooterDrop: true
    }
  }

  componentDidMount() {
    const options = {
      root: document.querySelector('.modal-content'),
      rootMargin: '1px',
      threshold: 1.0
    }

    const accounts = this.props.data.status.amazon_seller_accounts || []
    const lastAccount = accounts[accounts.length - 1]
    // this should never happen, but just incase
    if (!lastAccount) {
      return
    }
    const target = document.querySelector(`#mws_product_${lastAccount.id}`)

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.target === target) {
          this.setState({ showFooterDrop: !entry.isIntersecting })
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)
    if (target instanceof HTMLElement) {
      observer.observe(target)
    }
  }

  renderStage(stage, account) {
    const isComplete = account[stage.name] >= 100
    const status = isComplete ? 'Complete' : 'In Progress'
    return (
      <StageCard
        isComplete={isComplete}
        key={`${stage.name}_${account.id}`}
        id={`${stage.name}_${account.id}`}
        noBottomBorder
        noTopBorder
        noLeftBorder
        noRightBorder>
        {/* using just noBorder also kills the margins 🤷‍ */}
        <h4>{status}</h4> <h5>{stage.title}</h5>
        {!isComplete && <CustomRing size="16px" lineWidth="2px" />}
        <p>{stage.description}</p>
      </StageCard>
    )
  }

  renderAccount(account) {
    return (
      <AccountWrapper key={account.id}>
        <h3>Seller Central Account:</h3>
        <br />
        {Array.isArray(account.countries) &&
          account.countries.map(country => (
            <FlagIcon
              country={country.country_code}
              size="20px"
              key={country.country_code}
            />
          ))}
        <h4>{account.label}</h4>
        <Columns>
          <Column basis="unset">
            {SYNC_STAGES.map(stage => this.renderStage(stage, account))}
          </Column>
        </Columns>
      </AccountWrapper>
    )
  }

  render() {
    let accounts = this.props.data.status.amazon_seller_accounts || []
    const globalAccounts = this.props.globalAccounts || []

    // merge accounts so we get all the name data etc
    accounts = accounts.map(account => {
      const globalAccount = globalAccounts.find(a => a.id === account.id) || {}

      return {
        ...account,
        ...globalAccount
      }
    })

    return (
      <CustomModal
        showFooterDrop={this.state.showFooterDrop}
        hasMultipleAccounts={accounts.length > 1}
        isVisible={this.props.uiState.isVisible}
        title="Amazon Sync Details"
        handleBackdropClick={this.props.toggleModal}
        containerClasses="modal-parent"
        headerClasses="modal-header"
        contentClasses="modal-content">
        <IntroText>
          We are importing your data from Amazon, the full process may take
          several hours to complete.
        </IntroText>
        {accounts.map(account => this.renderAccount(account))}
      </CustomModal>
    )
  }
}

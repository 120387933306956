import styled from 'styled-components'

export const InfoWrapper = styled.div`
  width: 100%;

  .info-row {
    display: flex;
    padding-top: 20px;

    & > div {
      &:first-child {
        flex-basis: calc(50% - 10px);
        margin-right: 10px;
      }
      &:not(:first-child) {
        flex-basis: calc(50% - 10px);
        margin-left: 10px;
      }
      &:first-child:last-child {
        flex-basis: 100%;
        margin: 0;
      }
    }
  }
`

export const SettingsInfoWrapper = styled.div`
  margin: 20px 0;
`

export const SettingsRowWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GridInfoWrapper = styled(SettingsInfoWrapper)`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.singleColumn ? '100%' : '1fr 1fr')};
  grid-gap: 20px;

  ${props => props.noTopMargin && 'margin-top: 0;'}
`

export const DescriptionSubtitle = styled.div`
  opacity: 0.5;
`

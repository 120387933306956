import React from 'react'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import {
  ToggleButton,
  ToggleButtonSizeType,
  Button,
  ButtonType,
  Checkbox,
  LoadingPlaceholderGradient,
  Stepper
} from '@junglescout/edna'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { FEATURE_HIGHLIGHTS } from 'constants/setup_wizard'
import {
  AMAZON_MWS_PPC_SYNC_STEPS,
  MWS_PPC_SYNC_STEPS
} from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'
import { MARKETPLACES } from 'constants/countries'
import { MEDIA_QUERIES } from 'helpers/media_queries'
import { toggleAllReviewAutomation } from 'actions/review_automation'

import { safeSegmentCall, sendSegmentTrackEvent } from 'services/segment'
import { stripURLParams } from 'helpers/url'
import { FeatureHighlightList } from '../../setup_wizard_v2/common/FeatureHighlight'
import { AbstractModal, Footer } from './Abstract'

const ContentWrapper = styled.div`
  margin-top: 20px;
`
const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0 0 5px;
  color: ${props => props.theme.colors.grey900};
`
const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.colors.grey600};
  margin: 0;
`
const EnableWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props =>
    props.checked ? props.theme.colors.green100 : props.theme.colors.grey025};

  ${props =>
    props.checked &&
    css`
      border: 1px solid ${props.theme.colors.green700};
    `}

  padding: 24px;
  margin-top: 28px;
  align-items: center;
`
const StyledLabel = styled.span`
  font-weight: 600;
  font-size: 13px;
  @media (min-width: ${MEDIA_QUERIES.phone.size}px) {
    font-size: 16px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  flex: 3;
  justify-content: flex-start;
`
const EnableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${props =>
    props.checked &&
    ` border-bottom: 1px solid ${props.theme.colors.green700};
      margin-bottom: 18px;
      padding-bottom: 16px;
    `}
`
const ToggleWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
const Disclaimer = styled.div`
  ${TEXT_STYLES.BodyBlack}
  margin-bottom: 16px;
`
const StyledCheckbox = styled.div`
  display: flex;
  margin-bottom: 14px;
`
const MarketplacesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

class AccountSetupStep extends AbstractModal {
  state = {
    toggleOn: true,
    enabledMarketplaces: []
  }

  componentDidMount() {
    safeSegmentCall(() => {
      sendSegmentTrackEvent(' Account Setup Enable RA Viewed', {
        pageURL: stripURLParams(window.location.href)
      })
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { amazonSellerAccounts } = props

    const newlyAddedAccount =
      amazonSellerAccounts[amazonSellerAccounts.length - 1]
    const selectedAccountMarketplaces = newlyAddedAccount?.countries

    // We want to force user to enable all marketplaces for all accounts, so we set all checkboxes to checked by default
    const marketplacesData =
      selectedAccountMarketplaces?.map(({ country_code, marketplace_id }) => ({
        country_code,
        review_automation_enabled: true,
        amazon_seller_account_id: newlyAddedAccount?.id,
        id: marketplace_id
      })) || []
    const { enabledMarketplaces } = state

    if (!enabledMarketplaces.length && marketplacesData.length) {
      return { enabledMarketplaces: marketplacesData, toggleOn: state.toggleOn }
    }
    return null
  }

  handleCheckboxClick(selection, i) {
    this.setState(prevState => {
      const updatedMarketplacesSelection = [...prevState.enabledMarketplaces]
      updatedMarketplacesSelection[i].review_automation_enabled = selection

      return {
        ...prevState,
        enabledMarketplaces: updatedMarketplacesSelection
      }
    })
  }

  renderSteps() {
    return (
      <Stepper steps={MWS_PPC_SYNC_STEPS} activeIndex={this.activeStepIndex} />
    )
  }

  renderRaMarketplaces() {
    const { t } = this.props
    const { toggleOn, enabledMarketplaces } = this.state

    if (!toggleOn) {
      return null
    }
    if (!enabledMarketplaces?.length) {
      return <LoadingPlaceholderGradient width="100%" height="60px" />
    }

    return (
      <MarketplacesWrapper>
        <Disclaimer>
          {t(
            'setup_wizard:ReviewAutomationStep.disclaimer',
            'Review requests will be sent for these marketplaces'
          )}
        </Disclaimer>
        {enabledMarketplaces.map(({ country_code }, i) => (
          <StyledCheckbox key={country_code}>
            <Checkbox
              id={country_code}
              label={MARKETPLACES[country_code.toLowerCase()].name}
              checked={enabledMarketplaces[i].review_automation_enabled}
              onChange={selection => this.handleCheckboxClick(selection, i)}
            />
          </StyledCheckbox>
        ))}
      </MarketplacesWrapper>
    )
  }

  renderContent() {
    const { t, fromSetupPage } = this.props
    const { toggleOn } = this.state

    // Skip this step if coming from setup wizard, already handled in setup wizard itself
    if (fromSetupPage) {
      return this.onNextClick()
    }

    return (
      <ContentWrapper>
        <Title>
          {t(
            'setup_wizard:Chart.disabled_state.title',
            'Get more product reviews'
          )}
        </Title>
        <Subtitle>
          {t(
            'setup_wizard:ReviewAutomationStep.subtitle',
            'Sellers who enable Review Automation on average get 15% more product reviews than those who don’t'
          )}
        </Subtitle>
        <FeatureHighlightList list={FEATURE_HIGHLIGHTS} />
        <EnableWrapper checked={toggleOn}>
          <EnableHeader checked={toggleOn}>
            <LabelWrapper>
              <StyledLabel>
                {t(
                  'review_automation:generic.enable_review_automation',
                  'Enable Review Automation'
                )}
              </StyledLabel>
            </LabelWrapper>
            <ToggleWrapper>
              <ToggleButton
                handleToggleClick={() =>
                  this.setState(prev => ({ ...prev, toggleOn: !toggleOn }))
                }
                size={ToggleButtonSizeType.DEFAULT}
                checked={toggleOn}
                uncheckedColor={COLORS.grey300}
                title="Toggle Review Automation"
              />
            </ToggleWrapper>
          </EnableHeader>
          {this.renderRaMarketplaces()}
        </EnableWrapper>
      </ContentWrapper>
    )
  }

  renderFooter() {
    const { t, fromSetupPage } = this.props

    const dataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardAccountSetupNext
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.AccountSetupNext

    return (
      <Footer>
        <Button
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          isLoading={this.nextButtonLoading}
          disabled={this.nextButtonDisabled}
          dataId={dataId}>
          {t('generic:Next', 'Next')}
        </Button>
      </Footer>
    )
  }

  onNextClick() {
    const {
      showMwsSyncModal,
      isUpgrade,
      toggleAllReviewAutomation
    } = this.props
    const { toggleOn, enabledMarketplaces } = this.state

    if (toggleOn) {
      toggleAllReviewAutomation(enabledMarketplaces)
    }

    showMwsSyncModal(this.nextModal, isUpgrade)
  }

  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.congratulations
  }

  get nextButtonLoading() {
    return false
  }

  get nextButtonDisabled() {
    const { toggleOn, enabledMarketplaces } = this.state

    return (
      toggleOn &&
      !enabledMarketplaces.find(
        marketplace => marketplace.review_automation_enabled
      )
    )
  }

  get backButtonDisabled() {
    return true
  }

  get activeStepIndex() {
    return 3
  }
}

export default withTranslation(['setup_wizard'])(
  connect(
    null,
    {
      toggleAllReviewAutomation
    }
  )(AccountSetupStep)
)

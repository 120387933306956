import React, { Component } from 'react'
import styled from 'styled-components'

import { MWS_PPC_SYNC_STEPS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { Button, ButtonType, Stepper } from '@junglescout/edna'

import { WatchTutorial } from '../../common/junglekit/ui_elements/WatchTutorial/WatchTutorial'

export const Footer = styled.div`
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  & > button:last-child {
    display: inline;
    float: right !important;
    padding: 7px !important;
  }

  & > button:first-child {
    display: inline;
    float: ${props => props.float || 'left'};
    padding: 7px !important;
  }
`
export const HeaderTutorialIcon = styled(WatchTutorial)`
  float: right;
  margin: 0;
  padding: 0 5px;
  margin-bottom: -10px;
  position: relative;
  top: -5px;
  margin-right: 20px;
  width: auto;
`
const NextButton = styled(Button)`
  visibility: ${props => (props.hideButton ? 'hidden' : 'visible')};
`
const AbstractWrapper = styled.div`
  min-width: ${props => props.$width};
  max-width: ${props => props.$width};
`

export class AbstractModal extends Component {
  onBackClick() {
    const { showMwsSyncModal, isUpgrade } = this.props
    showMwsSyncModal(this.previousModal, isUpgrade)
  }

  onNextClick() {
    const { showMwsSyncModal, isUpgrade } = this.props
    showMwsSyncModal(this.nextModal, isUpgrade)
  }

  onStepClicked(step) {
    const { showMwsSyncModal, isUpgrade } = this.props
    showMwsSyncModal(step.modal, isUpgrade)
  }

  renderSteps() {
    return (
      <Stepper steps={MWS_PPC_SYNC_STEPS} activeIndex={this.activeStepIndex} />
    )
  }

  renderContent() {
    return null
  }

  renderFooter() {
    const { t, fromSetupPage } = this.props
    const nextDataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardNext
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.Next
    return (
      <Footer>
        {this.previousModal && (
          <Button
            btnType={ButtonType.TERTIARY}
            onClick={() => this.onBackClick()}
            disabled={this.backButtonDisabled}
            dataId={MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.Back}
            margin="0">
            {t('generic:Back', 'Back')}
          </Button>
        )}
        <NextButton
          hideButton={this.hideNextButton}
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          isLoading={this.nextButtonLoading}
          disabled={this.nextButtonDisabled}
          margin="0"
          dataId={nextDataId}>
          {t('generic:Next', 'Next')}
        </NextButton>
      </Footer>
    )
  }

  render() {
    const content = this.renderContent()
    // if there's no content - just render nothing, it looks odd having a footer and header with no content
    if (content === null) {
      return null
    }

    return (
      <AbstractWrapper $width={this.width}>
        {this.renderSteps()}
        {content}
        {this.renderFooter()}
      </AbstractWrapper>
    )
  }

  get nextModal() {
    return null
  }

  get previousModal() {
    return null
  }

  get backButtonDisabled() {
    return false
  }

  get nextButtonDisabled() {
    return false
  }

  get nextButtonLoading() {
    return false
  }

  get activeStepIndex() {
    throw new Error('get activeStepIndex must be implemented')
  }

  get width() {
    return '460px'
  }

  get hideNextButton() {
    return false
  }
}
